
import { defineComponent, ref, onMounted, watch, reactive } from 'vue';
import { useRoute } from 'vue-router';
import Container from '@/components/Container.vue';
import Pagination from '@/components/Pagination/index.vue';
import router from '@/router/index';
import ListFilter from './ListFilter.vue';
import ListTable from './ListTable.vue';
import partnerAPI from '@/service/partnerAPI';

export type MakeOfferListParams = {
  keyword?: string;
  page: number;
  pageSize: number;
  sortBy?: string
};

export default defineComponent({
  name: 'MakeOffer',
  components: {
    Container,
    ListFilter,
    ListTable,
    Pagination,
  },
  props: {},
  setup() {
    const route = useRoute();
    const query = route.query;

    const filterParams = reactive<MakeOfferListParams>({
      keyword: (query.keyword as string) || '',
      page: Number(query.page) || 0,
      pageSize: Number(query.pageSize) || 10,
      sortBy: (query.sortBy as string) || undefined
    });


    const productList = ref({
      success: true,
      data: [],
      message: '',
      total: 0,
    });

    const handleChangeFilterParams = (key: string, value: any) => {
      filterParams[key] = value;
    };

    const handleChangePage = (page: number) => {
      filterParams.page = page - 1;
      executeSearch();
    };

    const handleChangePageSize = (pageSize: number) => {
      filterParams.page = 0;
      filterParams.pageSize = pageSize;
      executeSearch();
    };

    const handleClickSearch = () => {
      console.log('베르나');
      filterParams.page = 0;
      executeSearch();
    };

    const handleChangeSort = (sortBy) => {
      filterParams.sortBy = sortBy
      executeSearch()
    }

    /**
     * 검색 실행
     */
    const executeSearch = () => {
      //fetchMakeOfferLists();
      router.push({
        path: window.location.pathname,
        query: {
          keyword: filterParams.keyword || '',
          page: filterParams.page || 0,
          pageSize: filterParams.pageSize || 10,
          sortBy: String(filterParams.sortBy) || ''
        },
      });
    };

    const fetchMakeOfferLists = async () => {
      try {
        const { data } = await partnerAPI.partnerMakeOffer.makeOfferListUsingGET(
            {
              // 필터가 적용되어있지 않으면 타이틀만 사용한다
              keyword: filterParams.keyword,
              sortBy: filterParams.sortBy,
              page: filterParams.page,
              pageSize: filterParams.pageSize
            }
        );
        productList.value = data as any;
      } catch ({ response }) {
        console.error(response.data);
        alert(response.data.message);
      }
    };

    onMounted(fetchMakeOfferLists);

    watch([filterParams], (newFilter, newIsFilterOn) => {
      console.log('watch filter', newFilter, newIsFilterOn);
      fetchMakeOfferLists();
    });


    return {
      filterParams,
      productList,
      handleChangePage,
      handleChangePageSize,
      handleChangeSort,
      executeSearch,
      handleClickSearch,
      handleChangeFilterParams,
      fetchMakeOfferLists,
    };
  },
});
