<template>
  <table
      v-if="products"
      class="ListTable table-fixed w-full text-center text-sm mt-6"
  >
    <thead class="bg-gray-200">
    <tr class="h-10">
      <td class="w-44">
        <div class="flex justify-center items-center">
          LOT#
          <template v-if="sort.lot === 'NONE'">
            <button class="sorting-button" @click="sortHandler('lot','ASC')">
              <sorting-default-icon/>
            </button>
          </template>
          <template v-else-if="sort.lot === 'ASC'">
            <button class="sorting-button ascending" @click="sortHandler('lot', 'DESC')">
              <sorting-ascending-icon/>
            </button>
          </template>
          <template v-else-if="sort.lot === 'DESC'">
            <button class="sorting-button descending" @click="sortHandler('lot', 'NONE')">
              <sorting-descending-icon/>
            </button>
          </template>
        </div>
      </td>
      <td class="">상품명</td>
      <td class="w-30">카테고리</td>
      <td class="w-24">
        <div class="flex justify-center items-center">
          시작가
          <template v-if="sort.startPrice === 'NONE'">
            <button class="sorting-button" @click="sortHandler('startPrice', 'ASC')">
              <sorting-default-icon/>
            </button>
          </template>
          <template v-else-if="sort.startPrice === 'ASC'">
            <button class="sorting-button ascending" @click="sortHandler('startPrice', 'DESC')">
              <sorting-ascending-icon/>
            </button>
          </template>
          <template v-else-if="sort.startPrice === 'DESC'">
            <button class="sorting-button descending" @click="sortHandler('startPrice', 'NONE')">
              <sorting-descending-icon/>
            </button>
          </template>
        </div>
      </td>
      <td class="w-36">
        <div class="flex justify-center items-center">
          BUY NOW 가격
          <template v-if="sort.buynowPrice === 'NONE'">
            <button class="sorting-button" @click="sortHandler('buynowPrice', 'ASC')">
              <sorting-default-icon/>
            </button>
          </template>
          <template v-else-if="sort.buynowPrice === 'ASC'">
            <button class="sorting-button ascending" @click="sortHandler('buynowPrice', 'DESC')">
              <sorting-ascending-icon/>
            </button>
          </template>
          <template v-else-if="sort.buynowPrice === 'DESC'">
            <button class="sorting-button descending" @click="sortHandler('buynowPrice', 'NONE')">
              <sorting-descending-icon/>
            </button>
          </template>
        </div>
      </td>
      <td class="w-32">
        <div class="flex justify-center items-center">
          현재가
          <template v-if="sort.nowPrice === 'NONE'">
            <button class="sorting-button" @click="sortHandler('nowPrice', 'ASC')">
              <sorting-default-icon/>
            </button>
          </template>
          <template v-else-if="sort.nowPrice === 'ASC'">
            <button class="sorting-button ascending" @click="sortHandler('nowPrice', 'DESC')">
              <sorting-ascending-icon/>
            </button>
          </template>
          <template v-else-if="sort.nowPrice === 'DESC'">
            <button class="sorting-button descending" @click="sortHandler('nowPrice', 'NONE')">
              <sorting-descending-icon/>
            </button>
          </template>
        </div>
      </td>
      <td class="w-20 ">
        <div class="flex justify-center items-center">
          Bid수
          <template v-if="sort.biddingCount === 'NONE'">
            <button class="sorting-button" @click="sortHandler('biddingCount', 'ASC')">
              <sorting-default-icon/>
            </button>
          </template>
          <template v-else-if="sort.biddingCount === 'ASC'">
            <button class="sorting-button ascending" @click="sortHandler('biddingCount', 'DESC')">
              <sorting-ascending-icon/>
            </button>
          </template>
          <template v-else-if="sort.biddingCount === 'DESC'">
            <button class="sorting-button descending" @click="sortHandler('biddingCount','NONE')">
              <sorting-descending-icon/>
            </button>
          </template>
        </div>
      </td>
      <td class="w-16 text-center">
        1:1문의
      </td>
      <td class="w-32">
        <div class="flex justify-center items-center">
          OFFER금액
          <template v-if="sort.offerPrice === 'NONE'">
            <button class="sorting-button" @click="sortHandler('offerPrice', 'ASC')">
              <sorting-default-icon/>
            </button>
          </template>
          <template v-else-if="sort.offerPrice === 'ASC'">
            <button class="sorting-button ascending" @click="sortHandler('offerPrice', 'DESC')">
              <sorting-ascending-icon/>
            </button>
          </template>
          <template v-else-if="sort.offerPrice === 'DESC'">
            <button class="sorting-button descending" @click="sortHandler('offerPrice','NONE')">
              <sorting-descending-icon/>
            </button>
          </template>
        </div>
      </td>
      <td class="w-32">
        <div class="flex justify-center items-center">
          OFFER시간
          <template v-if="sort.offeredAt === 'NONE'">
            <button class="sorting-button" @click="sortHandler('offeredAt', 'ASC')">
              <sorting-default-icon/>
            </button>
          </template>
          <template v-else-if="sort.offeredAt === 'ASC'">
            <button class="sorting-button ascending" @click="sortHandler('offeredAt', 'DESC')">
              <sorting-ascending-icon/>
            </button>
          </template>
          <template v-else-if="sort.offeredAt === 'DESC'">
            <button class="sorting-button descending" @click="sortHandler('offeredAt', 'NONE')">
              <sorting-descending-icon/>
            </button>
          </template>
        </div>
      </td>
      <td class="w-16 text-center">
        구매자
      </td>
      <td class="w-32">상태</td>
    </tr>
    </thead>
    <tbody>
    <tr
        class="h-20"
        v-for="(item, index) in products"
        :key="index"
    >
      <!-- 이미지 + LOT# -->
      <td @click="handleClickRow(item)" class="cursor-pointer">
        <div class="flex items-center">
          <div
              v-if="item.productImage"
              class="w-15 h-15 bg-gray-100 bg-cover"
              :style="{
                backgroundImage: `url(${item.productImage})`,
              }"
          ></div>
          <div
              v-else
              class="w-15 h-15 bg-gray-100 bg-cover"
              :style="{
                backgroundImage: `url(https://via.placeholder.com/120x120/F3F4F6?text=x)`,
              }"
          ></div>
          <div class="ml-4">{{ item.lot }}</div>
        </div>
      </td>

      <!-- 상품명 -->
      <td @click="handleClickRow(item)" class="text-left cursor-pointer">
        <div>{{ item.artistName }}</div>
        <div>{{ item.title }}</div>
      </td>

      <!-- 카테고리 -->
      <td class="break-all text-xs">
        {{ categoryDepth1Obj[item.categoryDepth1Id] || '' }} > <br>
        {{ categoryDepth2Obj[item.categoryDepth2Id] || '' }}
      </td>

      <!-- 시작가 -->
      <td class="text-right text-xs">
        <p>
          <template v-if="item.startPrice">
            {{ formatNumber(item.startPrice) }}
          </template>
          <template v-else>
            -
          </template>
        </p>
      </td>

      <!--  buy now가격-->
      <td class="text-right text-xs">
        <p>
          <template v-if="item.buynowPrice">
            {{ formatNumber(item.buynowPrice) }}
          </template>
          <template v-else>
            -
          </template>
        </p>
      </td>

      <!-- 현재가 -->
      <td class="text-right text-xs">
        <p>
          <template v-if="item.nowPrice">
            {{ formatNumber(item.nowPrice) }}
          </template>
          <template v-else>
            -
          </template>
        </p>
      </td>
      <!--        Bid수-->
      <td class="text-right">
        <template v-if="item.enableBid">
          <p class="text-xs" style="color: #171717">{{ biddingCount(item.biddingCount) }}</p>
        </template>
        <template v-else>
          -
        </template>
      </td>
      <td class="text-center">
        <router-link :to="`/app/qna?productId=${item.productId}`" v-if="item.qnaCount && item.qnaCount > 0">
          <mark class="status__label red-full">1:1문의</mark>
        </router-link>
      </td>
      <!-- OFFER 금액 -->
      <td class="text-xs">
        {{ formatNumber(item.offerPrice) }}
      </td>
      <!-- OFFER 기간 -->
      <td class="text-xs">
        <div v-if="item.offeredAt">
          {{ format(new Date(item.offeredAt), 'yyyy/MM/dd HH:mm') }}
        </div>
        <div v-else>-</div>
      </td>
      <!--        구매자-->
      <td class="text-xs">
        <div v-if="item.buyerName">
          {{ item.buyerName }}
        </div>
        <div v-else> -</div>
      </td>

      <!-- 상태 -->
      <td>
        <template v-if="item.accept === 'request'">
          <button
              @click="denyMakeOfferRequest(item.buyerName, item.productMakeOfferId)"
              class="h-7 w-20 text-xs border border-gray-500"
          >거절하기</button>
          <button
              @click="acceptMakeOfferRequest(item.buyerName, item.productMakeOfferId)"
              class="h-7 w-20 text-xs border border-primary bg-primary text-white mt-1"
          >수락하기</button>
        </template>
        <template v-else>
          <strong class="text-xs">{{ item.accept === 'accept' ? '수락' : '거절' }}</strong>
          <p class="text-xs">{{ format(new Date(item.acceptedAt), 'yyyy/MM/dd HH:mm') }}</p>
        </template>
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import {defineComponent, onUpdated, PropType, ref} from 'vue';
import {format} from 'date-fns';
import router from '@/router';
import useCategoryOptions from '@/mixins/useCategoryOptions';
import partnerAPI from '@/service/partnerAPI';
import SortingDefaultIcon from '@/components/icons/SortingDefaultIcon.vue';
import SortingDescendingIcon from '@/components/icons/SortingDescendingIcon.vue';
import SortingAscendingIcon from '@/components/icons/SortingAscendingIcon.vue';
import getServerErrorMessage from '@/utils/getServerErrorMessage';


export default defineComponent({
  name: 'ListTable',
  components: {
    SortingAscendingIcon,
    SortingDefaultIcon,
    SortingDescendingIcon
  },
  props: {
    products: Object as PropType<any>,
    sortBy: String
  },
  setup: function (props, {emit}) {
    const {categoryDepth1Obj, categoryDepth2Obj} = useCategoryOptions();

    const sort = ref(
        {
          lot: 'NONE',
          startPrice: 'NONE',
          buynowPrice: 'NONE',
          nowPrice: 'NONE',
          biddingCount: 'NONE',
          offerPrice: 'NONE',
          offeredAt: 'NONE'
        }
    );
    if (props.sortBy) {
      const [key, value] = props.sortBy.split(':');
      if (key && value) {
        sort.value[key] = value;
      }
    }

    const sortHandler = (name, value) => {
      Object.keys(sort.value).forEach(key => sort.value[key] = 'NONE');
      sort.value[name] = value;
      if (value === 'NONE') {
        emit('changeSort', undefined);
        return;
      }
      emit('changeSort', `${name}:${value}`);
    };

    onUpdated(() => {
      console.log('products', props.products);
    });


    const biddingCount = (count) => {
      return `${count} 건`;
    };

    const formatNumber = (number) => {
      if (number === 0) {
        return '0원';
      }
      if (!number) {
        return '-';
      }
      return number.toLocaleString() + '원';
    };

    const acceptMakeOfferRequest = (offerName, productMakeOfferId) => {
      if (
          window.confirm(
              `${offerName}님의 가격제안을 수락하시겠습니까?\n제안 고객님께 구매링크가 전달됩니다.`
          )
      ) {
        changeMakeOfferRequest(productMakeOfferId, 'accept');
      }
    }

    const denyMakeOfferRequest = (offerName, productMakeOfferId) => {
      if (
          window.confirm(
              `${offerName}님의 가격제안을 거절하시겠습니까?\n판매상태는 유지되며 다른 금액으로\n제안을 받으실 수 있습니다.`
          )
      ) {
        changeMakeOfferRequest(productMakeOfferId, 'deny');
      }
    }

    const changeMakeOfferRequest = async (productMakeOfferId, condition) => {
      try {
        const { data } = await partnerAPI.partnerMakeOffer.updateMakeOfferUsingPUT({
          param: {
            accept: condition,
            productMakeOfferId,
          }
        });
        alert((data as any).message)
        emit('refresh')
      } catch (e) {
        console.error(e);
        alert(getServerErrorMessage(e));
      }
    };

    const handleClickRow = (item) => {
      // 상품상세로 이동
      router.push({
        path: `/app/sales/detail/${item.productId}`,
      });
    };

    return {
      format,
      biddingCount,
      categoryDepth1Obj,
      categoryDepth2Obj,
      formatNumber,
      handleClickRow,
      sort,
      sortHandler,
      denyMakeOfferRequest,
      acceptMakeOfferRequest
    };
  },
});
</script>

<style lang="scss" scoped>
.sorting-button {
  width: 20px;
  height: 20px;

  svg {
    margin: 0 auto;
  }
}

.ListTable {
  thead {
    tr {
      border: 1px solid #DDDDDD;
    }
  }

  tbody {
    tr {
      border-bottom: 1px dashed #dddddd;
    }
  }

  td {
    padding: 8px 10px;
  }
}
.status__label {
  display: inline-block;
  padding: 2px 4px;
  letter-spacing: -0.2px;
  border-radius: 2px;
  border: 1px solid;
  color: #1890FF;
  background: none;
  font-size: 10px;
  line-height: 12px;

  &.blue {
    color: #4176FF;
  }

  &.red {
    color: #F00000;
  }

  &.red-full {
    color: #fff;
    background-color: #FF7875;
    border-color: #FF7875;
  }
}
</style>
