
import {defineComponent, onUpdated, PropType, ref} from 'vue';
import {format} from 'date-fns';
import router from '@/router';
import useCategoryOptions from '@/mixins/useCategoryOptions';
import partnerAPI from '@/service/partnerAPI';
import SortingDefaultIcon from '@/components/icons/SortingDefaultIcon.vue';
import SortingDescendingIcon from '@/components/icons/SortingDescendingIcon.vue';
import SortingAscendingIcon from '@/components/icons/SortingAscendingIcon.vue';
import getServerErrorMessage from '@/utils/getServerErrorMessage';


export default defineComponent({
  name: 'ListTable',
  components: {
    SortingAscendingIcon,
    SortingDefaultIcon,
    SortingDescendingIcon
  },
  props: {
    products: Object as PropType<any>,
    sortBy: String
  },
  setup: function (props, {emit}) {
    const {categoryDepth1Obj, categoryDepth2Obj} = useCategoryOptions();

    const sort = ref(
        {
          lot: 'NONE',
          startPrice: 'NONE',
          buynowPrice: 'NONE',
          nowPrice: 'NONE',
          biddingCount: 'NONE',
          offerPrice: 'NONE',
          offeredAt: 'NONE'
        }
    );
    if (props.sortBy) {
      const [key, value] = props.sortBy.split(':');
      if (key && value) {
        sort.value[key] = value;
      }
    }

    const sortHandler = (name, value) => {
      Object.keys(sort.value).forEach(key => sort.value[key] = 'NONE');
      sort.value[name] = value;
      if (value === 'NONE') {
        emit('changeSort', undefined);
        return;
      }
      emit('changeSort', `${name}:${value}`);
    };

    onUpdated(() => {
      console.log('products', props.products);
    });


    const biddingCount = (count) => {
      return `${count} 건`;
    };

    const formatNumber = (number) => {
      if (number === 0) {
        return '0원';
      }
      if (!number) {
        return '-';
      }
      return number.toLocaleString() + '원';
    };

    const acceptMakeOfferRequest = (offerName, productMakeOfferId) => {
      if (
          window.confirm(
              `${offerName}님의 가격제안을 수락하시겠습니까?\n제안 고객님께 구매링크가 전달됩니다.`
          )
      ) {
        changeMakeOfferRequest(productMakeOfferId, 'accept');
      }
    }

    const denyMakeOfferRequest = (offerName, productMakeOfferId) => {
      if (
          window.confirm(
              `${offerName}님의 가격제안을 거절하시겠습니까?\n판매상태는 유지되며 다른 금액으로\n제안을 받으실 수 있습니다.`
          )
      ) {
        changeMakeOfferRequest(productMakeOfferId, 'deny');
      }
    }

    const changeMakeOfferRequest = async (productMakeOfferId, condition) => {
      try {
        const { data } = await partnerAPI.partnerMakeOffer.updateMakeOfferUsingPUT({
          param: {
            accept: condition,
            productMakeOfferId,
          }
        });
        alert((data as any).message)
        emit('refresh')
      } catch (e) {
        console.error(e);
        alert(getServerErrorMessage(e));
      }
    };

    const handleClickRow = (item) => {
      // 상품상세로 이동
      router.push({
        path: `/app/sales/detail/${item.productId}`,
      });
    };

    return {
      format,
      biddingCount,
      categoryDepth1Obj,
      categoryDepth2Obj,
      formatNumber,
      handleClickRow,
      sort,
      sortHandler,
      denyMakeOfferRequest,
      acceptMakeOfferRequest
    };
  },
});
